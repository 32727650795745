.parentDisable{
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.9;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.parentDisableRMA{
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.9;
  z-index: 998;
  height: 100%;
  width: 100%;
  margin-top: 4.5%;
}

.overlay-box {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color: #666666;
  background: #666666;
  opacity: .8;
  z-index: 1000;
}

.loaderRMA {
  position:absolute;
  border: 25px solid #f3f3f3;
  border-radius: 50%;
  border-top: 25px solid #1013c7;
  width: 120px;
  height: 120px;
  margin-left:50%;
  margin-top:15%;
  z-index: 998;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.loader {
  position:absolute;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #546e7a;
  width: 60px;
  height: 60px;
  margin-left:50%;
  margin-top:15%;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.loaderFix {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #546e7a;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}