.snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: rgb(27, 163, 0);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 1rem;
  }
  
  .snackbarError {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: rgb(224, 20, 20);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 1rem;
  }

  .snackbarError.show {
    visibility: visible; /* Show the snackbar */
   -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
   animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

  .snackbar.show {
    visibility: visible; /* Show the snackbar */
   -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
   animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }